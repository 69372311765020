import clsx from "clsx";
import { Fragment, useRef } from "react";
import { FIELD_TYPES } from "lib/consts";
import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import Option from "./Option";
import styles from "./styles.module.css";

// @todo make sure that there are no issues with number values (such as the radio input converting numbers to strings)

const Radio = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  onFatal,
  renderFormField,
  state,
  useSpacing,
  value,
}) => {
  const ref = useRef({});

  const {
    label,
    name = field.key,
    options,
    otherKey,
    otherLabel,
    required,
  } = field;

  const inputOptions = [...options];
  if(otherKey) {
    inputOptions.push({
      value: "other",
      label: "Other",
      details: [{
        type: FIELD_TYPES.TEXT,
        key: otherKey,
        label: otherLabel || "Other:",
      }],
    });
  }

  const handleChange = (evt) => {
    const fatal = evt.currentTarget.dataset["fatal"];
    if(fatal) {
      onFatal(fatal);
    } else {
      onChange(field, evt.currentTarget.value);
    }
  };

  const handleClick = (e) => {
    if (e.currentTarget.value === value) {
      onChange(field);
    }
  };

  const selectedOption = inputOptions?.find(o => o.value === value);

  const isUnanswered = getIsInputUnanswered(value);

  return (
    <>
      <div className={clsx({ [styles.fieldSpacing]: !!useSpacing })}>
        <Label
          indicateUnanswered={indicateUnanswered}
          isReadonly={isReadonly}
          isRequired={required}
          isUnanswered={isUnanswered}
        >
          {label}
        </Label>
        {isReadonly
          ? <div className={styles.readonlyValue}>{selectedOption?.label}</div>
          : <div className={styles.optionGroup}>
              {inputOptions.map((option, index) => {
                return (
                  <Fragment key={option.value}>
                    <input
                      checked={option.value === value}
                      onChange={handleChange}
                      onClick={handleClick}
                      className={styles.hiddenInput}
                      name={name}
                      value={option.value}
                      type="radio"
                      ref={(element) => (ref.current[index] = element)}
                      data-fatal={option.fatal}
                    />
                    <Option
                      onClick={() => ref.current[index].click()}
                      selected={option.value === value}
                    >
                      {option.label}
                    </Option>
                  </Fragment>
                );
              })}
            </div>
        }
      </div>
      {selectedOption?.details?.map(field => {
        return (
          <Fragment key={field.key}>
            {renderFormField({
              field,
              state,
              onChange,
            })}
          </Fragment>
        );
      })}
    </>
  );
};

export default Radio;
