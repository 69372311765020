import Dropdown from "./Dropdown";

// TimeInput input/output value is in this form: { hour: number, minute: number }
// valid values of "hour" are 0 - 23
// valid values of "minute" are 0 - 59
// the component implements Dropdown, so this data object is transformed into corresponding strings for value/label of each input option

// @todo dropdown label for 12:30 AM (and similar) need to be fixed

const TimeInput = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  useSpacing,
  value,
}) => {
  const { label, minHour = 6, maxHour = 20, required, step = 15 } = field;

  const options = [];
  for(let h = minHour; h <= maxHour; h++) {
    for(let m = 0; m < 60; m += step) {
      const value = `${h}:${m}`;

      let hour = h;
      if(h > 12) {
        hour %= 12;
      }

      const minute = m < 10 ? `0${m}` : m;

      let ampm = "AM";
      if(h > 11 && h < 24) {
        ampm = "PM";
      }

      const label = `${hour}:${minute} ${ampm}`;

      options.push({
        value,
        label
      });
    }
  }

  let inputValue;
  if(value) {
    inputValue = `${value.hour}:${value.minute}`;
  }

  const handleChange = (_, value = "") => {
    let newValue;
    if(!value.length) {
      newValue = undefined;
    } else {
      const valueStrings = value.split(":");
      const hour = parseInt(valueStrings[0]);
      const minute = parseInt(valueStrings[1]);
      newValue = {
        hour,
        minute
      };
    }
    onChange(field, newValue);
  };

  return (
    <Dropdown
      field={{ label, required, options, placeholder: "Choose a time..." }}
      indicateUnanswered={indicateUnanswered}
      isReadonly={isReadonly}
      onChange={handleChange}
      useSpacing={useSpacing}
      value={inputValue}
    />
  );
};

export default TimeInput;
