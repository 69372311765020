import clsx from "clsx";
import { DateTime } from "luxon";
import { TIMEZONE } from "lib/consts";
import { getDateTimeAsString } from "utils/date";
import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import styles from "./styles.module.css";

// @todo disabling typed input (forcing use of date picker) until we have form validation
const DateTimeInput = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  useSpacing,
  value,
}) => {
  let dateTimeValue = "";
  if(value) {
    const dateJS = new Date(value);
    const dateLuxon = DateTime.fromJSDate(dateJS, { zone: TIMEZONE });
    dateTimeValue = dateLuxon.toFormat("yyyy-LL-dd'T'HH:mm");
  }

  const handleChange = (evt) => {
    const inputString = evt.currentTarget.value;

    let date = "";
    if(inputString) {
      const dateLuxon = DateTime.fromISO(inputString, { zone: TIMEZONE });
      date = dateLuxon.toJSDate();
    }

    onChange(field, date);
  };

  const { label, required } = field;

  const isUnanswered = getIsInputUnanswered(dateTimeValue);

  return (
    <div className={clsx({ [styles.fieldSpacing]: !!useSpacing })}>
      <Label
        indicateUnanswered={indicateUnanswered}
        isReadonly={isReadonly}
        isRequired={required}
        isUnanswered={isUnanswered}
      >
        {label}
      </Label>
      {isReadonly
        ? <div className={styles.readonlyValue}>{getDateTimeAsString(value, { timeZone: TIMEZONE })}</div>
        : <input
            className={clsx(styles.input, styles.dateTime)}
            type="datetime-local"
            value={dateTimeValue}
            onChange={handleChange}
            onKeyDown={e => e.preventDefault()}
          />
      }
    </div>
  );
};

export default DateTimeInput;
