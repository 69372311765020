import Form from "components/Form";
import Modal from "..";

const FormModal = ({
  description,
  formFields,
  heading,
  onClose,
  onSubmit,
  showModal,
}) => {
  return (
    <Modal
      onClose={onClose}
      showModal={showModal}
      stackLevel="110"
    >
      <Form
        formConfig={{ pages: [{ description, fields: formFields, title: heading }] }}
        isInline
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default FormModal;
