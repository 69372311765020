import clsx from "clsx";
import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import styles from "./styles.module.css";

// @todo should not have "type" or "pattern" attributes for input element when values are undefined?
const TextInput = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  useSpacing,
  value,
}) => {
  const inputValue = value ?? "";

  const handleChange = (evt) => {
    onChange(field, evt.currentTarget.value);
  };

  const { label, inputType, required } = field;

  let pattern;
  if(inputType === "number") {
    pattern = '\\d*';
  }

  const isUnanswered = getIsInputUnanswered(inputValue);

  return (
    <div className={clsx({ [styles.fieldSpacing]: !!useSpacing })}>
      <Label
        indicateUnanswered={indicateUnanswered}
        isReadonly={isReadonly}
        isRequired={required}
        isUnanswered={isUnanswered}
      >
        {label}
      </Label>
      {isReadonly
        ? <div className={styles.readonlyValue}>{inputValue}</div>
        : <input
            className={clsx(styles.input, { [styles.textAlphabetical]: inputType !== "number" })}
            onChange={handleChange}
            type={inputType}
            value={inputValue}
            pattern={pattern}
          />
      }
    </div>
  );
};

export default TextInput;
