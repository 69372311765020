import { useUser as useAuth0User } from "@auth0/nextjs-auth0/client";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useState } from "react";
import Button from "components/Button";
import IconButton from "components/Button/Icon";
import { Hamburger, X } from "components/Icons";
import AdminOnly from "components/RoleCheck/AdminOnly";
import StaffOnly from "components/RoleCheck/StaffOnly";
import styles from "./index.module.css";

const Header = ({
  background,
  disableNavigation,
  parentPage
}) => {
  const router = useRouter();

  const [showNavMenu, setShowNavMenu] = useState(false);

  const { user } = useAuth0User();

  const buttonProps = {};
  if(parentPage?.href) {
    buttonProps.href = parentPage.href;
  } else if(parentPage) {
    buttonProps.onClick = router.back;
  }

  const renderNavLinks = () => (
    <>
      <StaffOnly>
        <Button href="/incidents" style="plain">Find a Claimant</Button>
        <Button href="/appointments" style="plain">Appointments</Button>
        <Button href="/exams" style="plain">Exams</Button>
        <Button href="/reports" style="plain">Reports</Button>
      </StaffOnly>
      <AdminOnly>
        <Button href="/admin" style="plain">Admin</Button>
      </AdminOnly>
      <Button href="/profile" style="plain">{user?.name}</Button>
      {parentPage &&
        <Button {...buttonProps} style="ghost_light">{parentPage.label}</Button>
      }
    </>
  );

  return (
    <div className={clsx(styles.root, { [styles.dark]: background === "dark" })}>
      {disableNavigation ?
        <div className={styles.title}>Foremost Independent Exams</div>
      :
        <>
          <Button href="/" style="plain">Foremost Independent Exams</Button>
          <div className={styles.navLinksMain}>
            {renderNavLinks()}
          </div>
          <div className={styles.hamburgerButton} onClick={() => setShowNavMenu(true)}>
            <Hamburger />
          </div>
        </>
      }
      <div className={clsx(styles.navMenu, { [styles.showMenu]: showNavMenu })}>
        <div className={styles.closeButton}>
          <IconButton onClick={() => setShowNavMenu(false)}>
            <X />
          </IconButton>
        </div>
        <div className={styles.navLinksMobile}>
          {renderNavLinks()}
        </div>
      </div>
    </div>
  );
};

export default Header;
