import clsx from "clsx";
import Button from "components/Button";
import InputGroup from "./InputGroup";
import Label from "./Label";
import styles from "./styles.module.css";

const RepeatableGroups = ({
  field,
  isReadonly,
  onChange,
  renderFormField,
  useSpacing,
  values = [],
}) => {
  const {
    addLabel,
    fields,
    isFixedCount,
    label,
    minimumOne,
  } = field;

  const repeatGroupValues = !values.length && minimumOne ? [{}] : values;

  const addField = () => {
    onChange(field, [...repeatGroupValues, {}]);
  };

  const removeField = () => {
    onChange(field, repeatGroupValues.toSpliced(repeatGroupValues.length - 1, 1));
  };

  const handleChange = (value, indexToChange) => {
    onChange(
      field,
      repeatGroupValues.map((state, stateIndex) => {
        if (stateIndex === indexToChange) {
          return value;
        } else {
          return state;
        }
      })
    );
  };

  const canChangeCount = !isReadonly && !isFixedCount;
  const showRemoveButton = minimumOne ? repeatGroupValues.length >= 2 : repeatGroupValues.length >= 1;

  return (
    <div className={clsx({ [styles.repeatableGroupsSpacing]: !!useSpacing })}>
      <Label
        isReadonly={isReadonly}
      >
        {label}
      </Label>
      <div className={styles.repeatableGroups}>
        {repeatGroupValues.map((state, index) => {
          return (
            <InputGroup
              field={{ fields, showGroupWrapper: true }}
              isReadonly={isReadonly}
              key={index}
              onChange={(_, state) => handleChange(state, index)}
              renderFormField={renderFormField}
              values={state}
            />
          );
        })}
      </div>
      {canChangeCount &&
        <div className={styles.buttonPanel}>
          <Button onClick={addField} style="fill_dark_small" useContentWidth>
            {addLabel || "Add"}
          </Button>
          {showRemoveButton &&
            <Button onClick={removeField} style="ghost_light_small" useContentWidth danger>
              Remove
            </Button>
          }
        </div>
      }
    </div>
  );
};

export default RepeatableGroups;
