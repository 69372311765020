import clsx from "clsx";
import { Fragment } from "react";
import { FIELD_TYPES } from "lib/consts";
import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import styles from "./styles.module.css";

const Dropdown = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  renderFormField,
  state,
  useSpacing,
  value,
}) => {
  const inputValue = value ?? "";

  const {
    label,
    noPlaceholder,
    options,
    otherKey,
    otherLabel,
    placeholder,
    required,
  } = field;

  const inputOptions = [...options];
  if(otherKey) {
    inputOptions.push({
      value: "other",
      label: "Other",
      details: [{
        type: FIELD_TYPES.TEXT,
        key: otherKey,
        label: otherLabel || "Other:",
      }],
    });
  }

  const handleChange = (evt) => {
    onChange(field, evt.currentTarget.value);
  };

  if(!noPlaceholder) {
    // if placeholder option does not exist, then add it
    const firstOptionValue = inputOptions[0]?.value;
    if(firstOptionValue || firstOptionValue === 0 || firstOptionValue === false) {
      inputOptions.unshift({ label: placeholder ?? "Choose..." });
    }
  }

  const selectedOption = inputOptions.find(option => {
    let optionValue = option.value ?? "";
    if(typeof optionValue === "number" && typeof inputValue === "string") {
      optionValue = `${optionValue}`;
    }
    return optionValue === inputValue;
  });

  const isUnanswered = getIsInputUnanswered(inputValue);

  return (
    <>
      <div className={clsx({ [styles.fieldSpacing]: !!useSpacing })}>
        <Label
          indicateUnanswered={indicateUnanswered}
          isReadonly={isReadonly}
          isRequired={required}
          isUnanswered={isUnanswered}
        >
          {label}
        </Label>
        {isReadonly
          ? <div className={styles.readonlyValue}>{!isUnanswered ? selectedOption?.label : ""}</div>
          : <select
              onChange={handleChange}
              className={clsx(styles.input, styles.dropdown)}
              value={inputValue}
            >
              {inputOptions.map((option, index) => (
                <option key={index} value={option.value ?? ""}>
                  {option.label}
                </option>
              ))}
            </select>
        }
      </div>
      {selectedOption?.details?.map(field => {
        return (
          <Fragment key={field.key}>
            {renderFormField({
              field,
              state,
              onChange,
            })}
          </Fragment>
        );
      })}
    </>
  );
};

export default Dropdown;
