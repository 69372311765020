import MultiselectButtons from "./MultiselectButtons";

const BodyParts = ({
  field,
  indicateUnanswered,
  isReadonly,
  onChange,
  renderFormField,
  state,
  useSpacing,
  values,
}) => {
  return (
    <MultiselectButtons
      field={field}
      indicateUnanswered={indicateUnanswered}
      isReadonly={isReadonly}
      onChange={onChange}
      options={field?.options}
      renderFormField={renderFormField}
      state={state}
      useSpacing={useSpacing}
      values={values}
    />
  );
};

export default BodyParts;
