import clsx from "clsx";
import { Fragment, useRef } from "react";
import { FIELD_TYPES } from "lib/consts";
import { getIsMultiselectUnanswered } from "utils/input";
import Label from "./Label";
import Option from "./Option";
import styles from "./styles.module.css";

const MultiselectButtons = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  renderFormField,
  state,
  useSpacing,
  values,
}) => {
  const ref = useRef({});

  const {
    label,
    options,
    otherKey,
    otherLabel,
    required,
  } = field;

  const inputOptions = [...options];
  if(otherKey) {
    inputOptions.push({
      value: "other",
      label: "Other",
      details: [{
        type: FIELD_TYPES.TEXT,
        key: otherKey,
        label: otherLabel || "Other:",
      }],
    });
  }

  const handleChange = (evt) => {
    onChange(field, {
      ...values,
      [evt.currentTarget.name]: evt.currentTarget.checked,
    });
  };

  const selectedOptions = inputOptions.filter(o => values[o.value]);
  const selectedOptionsAsString = selectedOptions.map(o => o.label).join(", ");

  const isUnanswered = getIsMultiselectUnanswered(values);

  return (
    <>
      <div className={clsx({ [styles.fieldSpacing]: !!useSpacing })}>
        <Label
          indicateUnanswered={indicateUnanswered}
          isReadonly={isReadonly}
          isRequired={required}
          isUnanswered={isUnanswered}
        >
          {label}
        </Label>
        {isReadonly
          ? <div className={styles.readonlyValue}>{selectedOptionsAsString}</div>
          : <div className={styles.optionGroup}>
              {inputOptions.map((option, index) => {
                return (
                  <Fragment key={index}>
                    <input
                      checked={values[option.value] ?? false}
                      onChange={handleChange}
                      className={styles.hiddenInput}
                      name={option.value}
                      type="checkbox"
                      ref={(element) => (ref.current[index] = element)}
                    />
                    <Option
                      onClick={() => ref.current[index].click()}
                      selected={values[option.value] ?? false}
                    >
                      {option.label}
                    </Option>
                  </Fragment>
                );
              })}
            </div>
        }
      </div>
      {selectedOptions.filter(o => !!o.details).flatMap(o => o.details.map(field => {
        return(
          <Fragment key={field.key}>
            {renderFormField({
              field,
              state,
              onChange,
            })}
          </Fragment>
        );
      }))}
    </>
  );
};

export default MultiselectButtons;
