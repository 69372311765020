import clsx from "clsx";
import { getUTCDateAsString, getDateInputFormat } from "utils/date";
import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import styles from "./styles.module.css";

// @todo disabling typed input (forcing use of date picker) until we have form validation
const DateInput = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  useSpacing,
  value,
}) => {
  const dateValue = getDateInputFormat(value);

  const handleChange = (evt) => {
    const inputString = evt.currentTarget.value;
    const date = inputString ? new Date(inputString) : "";
    onChange(field, date);
  };

  const { label, required } = field;

  const isUnanswered = getIsInputUnanswered(dateValue);

  return (
    <div className={clsx({ [styles.fieldSpacing]: !!useSpacing })}>
      <Label
        indicateUnanswered={indicateUnanswered}
        isReadonly={isReadonly}
        isRequired={required}
        isUnanswered={isUnanswered}
      >
        {label}
      </Label>
      {isReadonly
        ? <div className={styles.readonlyValue}>{getUTCDateAsString(value)}</div>
        : <input
            className={clsx(styles.input, styles.date)}
            onChange={handleChange}
            type="date"
            value={dateValue}
            onKeyDown={e => e.preventDefault()}
          />
      }
    </div>
  );
};

export default DateInput;
