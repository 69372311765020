import Button from "..";

const IconButton = ({
  children,
  disabled,
  download,
  href,
  onClick,
  openInNewWindow,
  type,
}) => {
  return (
    <Button
      disabled={disabled}
      download={download}
      href={href}
      onClick={onClick}
      openInNewWindow={openInNewWindow}
      type={type}
    >
      {children}
    </Button>
  );
};

export default IconButton;
