import clsx from "clsx";
import styles from "./option.module.css";

const Option = ({
  children,
  onClick,
  selected,
}) => {
  return (
    <button
      className={clsx(styles.buttonReset, styles.option, { [styles.selected]: !!selected })}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
};

export default Option;
