import { FIELD_TYPES } from "lib/consts";
import Radio from "./Radio";

const DynamicYesNo = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  renderFormField,
  state,
  useSpacing,
  value,
}) => {
  let { details } = field;
  if(!details) {
    details = [{
      type: FIELD_TYPES.TEXT,
      key: `${field.key}_details`,
      label: "If yes, please elaborate:",
    }];
  }

  const options = [
    {
      value: "no",
      label: "No",
    },
    {
      value: "yes",
      label: "Yes",
      details,
    },
  ];

  return (
    <Radio
      field={{...field, options}}
      indicateUnanswered={indicateUnanswered}
      isReadonly={isReadonly}
      onChange={onChange}
      renderFormField={renderFormField}
      state={state}
      useSpacing={useSpacing}
      value={value}
    />
  );
};

export default DynamicYesNo;
