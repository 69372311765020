export const CATEGORY_TYPES = {
  APPOINTMENT: {
    key: "appointment",
    pathname: "appointments/[id]",
    getApiPath: (id) => (id ? `/api/appointments/${id}` : "/api/appointments"),
  },
  CHAPERONE: {
    key: "chaperone",
    pathname: "chaperones/[id]",
    getApiPath: (id) => (id ? `/api/chaperones/${id}` : "/api/chaperones"),
  },
  CLAIMANT: {
    key: "claimant",
    pathname: "claimants/[id]",
    getApiPath: (id) => (id ? `/api/claimants/${id}` : "/api/claimants"),
  },
  DOCTOR: {
    key: "doctor",
    pathname: "doctors/[id]",
    getApiPath: (id) => (id ? `/api/doctors/${id}` : "/api/doctors"),
  },
  DOCUMENT: {
    key: "document",
    pathname: "documents/[id]",
    getApiPath: (documentId) => (`/api/documents/${encodeURIComponent(documentId)}`),
  },
  DOWNLOAD: {
    key: "download",
    pathname: "files/[name]/download",
    getApiPath: (storageFileName, { downloadName } = {}) => {
      let path = `/api/files/${encodeURIComponent(storageFileName)}/download`;
      if(downloadName) {
        path += `?downloadName=${encodeURIComponent(downloadName)}`;
      }
      return path;
    },
  },
  EXAM: {
    key: "exam",
    pathname: "exams/[id]",
    getApiPath: (id) => (id ? `/api/exams/${id}` : "/api/exams"),
  },
  REPORT: {
    key: "report",
    pathname: "reports/[id]",
    getApiPath: (id) => (id ? `/api/reports/${id}` : "/api/reports")
  },
  FILE: {
    key: "file",
    pathname: "files/[name]",
    getApiPath: (storageFileName) => (`/api/files/${encodeURIComponent(storageFileName)}`),
  },
  FILE_BY_UPLOAD: {
    getApiPath: (uploadId) => (`/api/files/uploads/${uploadId}`),
  },
  INCIDENT: {
    key: "incident",
    pathname: "incidents/[id]",
    getApiPath: (id) => (id ? `/api/incidents/${id}` : "/api/incidents"),
  },
  INSURER: {
    key: "insurer",
    pathname: "insurers/[id]",
    getApiPath: (id) => (id ? `/api/insurers/${id}` : "/api/insurers"),
  },
  LOCATION: {
    key: "location",
    pathname: "locations/[id]",
    getApiPath: (id) => (id ? `/api/locations/${id}` : "/api/locations"),
  },
  QUESTIONNAIRE: {
    key: "questionnaire",
    pathname: "questionnaires/[id]",
    getApiPath: (id) =>
      id ? `/api/questionnaires/${id}` : "/api/questionnaires",
  },
  VENDOR: {
    key: "vendor",
    pathname: "vendors/[id]",
    getApiPath: (id) => (id ? `/api/vendors/${id}` : "/api/vendors"),
  },
};

export const COVERAGE_TYPES = [{
  label: "Liability",
  value: "liability",
}, {
  label: "No Fault",
  value: "no fault",
}, {
  label: "Workers' Comp",
  value: "workers comp",
}];

export const getLabelFromCoverageValue = (value) => {
  return COVERAGE_TYPES.find(c => c.value === value)?.label;
};

export const DOCTOR_TYPES = {
  ACU: {
    key: "acu",
    label: "Acupuncturist",
    labelShort: "Acu.",
    practice: "Acupuncture",
  },
  CHIROPRACTOR: {
    key: "chiropractor",
    label: "Chiropractor",
    labelShort: "Chiro.",
    practice: "Chiropractic",
  },
  CHIRO_ACU: {
    key: "chiro_acu",
    label: "Chiropractor / Acupuncturist",
    labelShort: "Chiro. / Acu.",
    practice: "Chiropractic / Acupuncture",
  },
  NEURO: {
    key: "neuro",
    label: "Neurologist",
    labelShort: "Neuro.",
    practice: "Neurology",
  },
  ORTHO: {
    key: "ortho",
    label: "Orthopedist",
    labelShort: "Ortho.",
    practice: "Orthopedic",
  },
  PAIN: {
    key: "pain",
    label: "Pain Management / Anesthesiologist",
    labelShort: "Pain Mgmt. / Anesth.",
    practice: "Pain Management / Anesthesiology",
  },
  PMR: {
    key: "pmr",
    label: "Physical Medicine & Rehabilitation",
    labelShort: "PM&R",
    practice: "Physical Medicine & Rehabilitation",
  },
  PMR_ACU: {
    key: "pmr_acu",
    label: "Physical Medicine & Rehabilitation / Acupuncturist",
    labelShort: "PM&R / Acu.",
    practice: "Physical Medicine & Rehabilitation / Acupuncture",
  },
};

export const FIELD_TYPES = {
  CHECKBOX_GROUP: "checkbox_group",
  BODY_PARTS: "body_parts",
  RADIO: "radio",
  DROPDOWN: "dropdown",
  TEXT: "text",
  TEXTAREA: "textarea",
  DATE: "date",
  DATE_TIME: "date_time",
  DYNAMIC_YESNO: "dynamic_yesno",
  GROUP: "group",
  REPEATABLE_GROUPS: "repeatable_groups",
  FILE: "file",
  YEAR: "year",
  MONTH: "month",
  MONTH_YEAR: "month_year",
  SIGNATURE: "signature",
  TIME: "time",
};

export const FILE_TYPES = {
  PDF: "pdf",
};

export const FILE_TYPES_CONFIG = {
  [FILE_TYPES.PDF]: {
    mimeType: "application/pdf",
    text: "PDF",
  },
};

export const REPORT_SECTION_TYPES = {
  HEADER: "header",
  VENDOR_INFO: "vendor_info",
  ACCIDENT_INFO: "accident_info",
  TEXT: "text",
  TEXT_BLOCKS: "text_blocks",
  TEST_RESULTS: "test_results",
  SUBMITTED_FILES: "submitted_files",
  FOOTER: "footer",
  LIST: "list",
};

export const BG_TYPES = {
  black: "black",
};

export const REPORT_STATUSES = {
  NEEDS_REVIEW: "needs review",
  FINALIZED: "finalized",
};

export const APPOINTMENT_STATUS = {
  NOT_STARTED: {
    key: "not_started",
    text: "Not arrived",
  },
  CHECKED_IN: {
    key: "checked_in",
    text: "Checked in",
  },
  QUESTIONNAIRE_SUBMITTED: {
    key: "questionnaire_submitted",
    text: "Questionnaire submitted",
  },
  QUESTIONNAIRE_APPROVED: {
    key: "questionnaire_approved",
    text: "Ready for exams",
  },
  NO_SHOW: {
    key: "no_show",
    text: "No Show",
  },
};

export const APPOINTMENT_STATUS_OPTIONS = Object.keys(APPOINTMENT_STATUS).map(k => (
  {
    value: APPOINTMENT_STATUS[k].key,
    label: APPOINTMENT_STATUS[k].text
  }
));

export const EXAM_STATUS = {
  SCHEDULED: {
    key: "scheduled",
    text: "Scheduled",
  },
  CHECKING_IN: {
    key: "checking_in",
    text: "Checking in",
  },
  READY_TO_START: {
    key: "ready_to_start",
    text: "Ready to be seen",
  },
  IN_PROGRESS: {
    key: "in_progress",
    text: "In progress",
  },
  COMPLETE: {
    key: "complete",
    text: "Complete",
  },
};

export const EXAM_STATUS_OPTIONS = Object.keys(EXAM_STATUS).map(k => (
  {
    value: EXAM_STATUS[k].key,
    label: EXAM_STATUS[k].text
  }
));

export const QUESTIONNAIRE_STATUS = {
  NOT_STARTED: {
    key: "not_started",
    text: "Not started",
  },
  IN_PROGRESS: {
    key: "in_progress",
    text: "In progress",
  },
  SUBMITTED: {
    key: "submitted",
    text: "Submitted",
  },
  APPROVED: {
    key: "approved",
    text: "Approved",
  },
};

export const REPORT_STATUS = {
  NOT_GENERATED: {
    key: "not_generated",
    text: "Not generated",
  },
  AWAITING_PROOFREAD: {
    key: "awaiting_proofread",
    text: "Generated, awaiting proofread",
  },
  AWAITING_FILE_PRODUCTION: {
    key: "awaiting_file_production",
    text: "Proofread, awaiting PDF generation",
  },
  FILE_PRODUCED: {
    key: "file_produced",
    text: "PDF generated",
  },
};

export const REPORT_STATUS_OPTIONS = Object.keys(REPORT_STATUS).map(k => (
  {
    value: REPORT_STATUS[k].key,
    label: REPORT_STATUS[k].text
  }
));

export const INCIDENT_SORTS = [
  {
    dbConfig: { "claimant.nameLast": 1 },
    key: "alphabeticalUp",
    label: "Alphabetically (A-Z)",
  },
  {
    dbConfig: { "claimant.nameLast": -1 },
    key: "alphabeticalDown",
    label: "Alphabetically (Z-A)",
  },
];

export const INCIDENT_SORT_DROPDOWN_OPTIONS = INCIDENT_SORTS.map(s => (
  {
    label: s.label,
    value: s.key
  }
));

export const API_RESULTS_LIMIT = 100;

export const APPOINTMENT_SORTS = [
  {
    dbConfig: { "date": 1 },
    key: "appointmentTimeUp",
    label: "Appointment time ⬆",
  },
  {
    dbConfig: { "date": -1 },
    key: "appointmentTimeDown",
    label: "Appointment time ⬇",
  },
  {
    dbConfig: { "incident.claimant.nameLast": 1 },
    key: "alphabeticalUp",
    label: "Alphabetically (A-Z)",
  },
  {
    dbConfig: { "incident.claimant.nameLast": -1 },
    key: "alphabeticalDown",
    label: "Alphabetically (Z-A)",
  },
];

export const APPOINTMENT_SORT_DROPDOWN_OPTIONS = APPOINTMENT_SORTS.map(s => (
  {
    label: s.label,
    value: s.key
  }
));

export const EXAM_SORTS = [
  {
    dbConfig: { "appointment.date": 1 },
    key: "appointmentTimeUp",
    label: "Appointment time ⬆",
  },
  {
    dbConfig: { "appointment.date": -1 },
    key: "appointmentTimeDown",
    label: "Appointment time ⬇",
  },
  {
    dbConfig: { "appointment.incident.claimant.nameLast": 1 },
    key: "alphabeticalUp",
    label: "Alphabetically (A-Z)",
  },
  {
    dbConfig: { "appointment.incident.claimant.nameLast": -1 },
    key: "alphabeticalDown",
    label: "Alphabetically (Z-A)",
  },
];

export const EXAM_SORT_DROPDOWN_OPTIONS = EXAM_SORTS.map(s => (
  {
    label: s.label,
    value: s.key
  }
));

export const REPORT_SORTS = [
  {
    dbConfig: { "exam.appointment.date": 1 },
    key: "appointmentTimeUp",
    label: "Appointment time ⬆",
  },
  {
    dbConfig: { "exam.appointment.date": -1 },
    key: "appointmentTimeDown",
    label: "Appointment time ⬇",
  },
  {
    dbConfig: { "exam.appointment.incident.claimant.nameLast": 1 },
    key: "alphabeticalUp",
    label: "Alphabetically (A-Z)",
  },
  {
    dbConfig: { "exam.appointment.incident.claimant.nameLast": -1 },
    key: "alphabeticalDown",
    label: "Alphabetically (Z-A)",
  },
];

export const REPORT_SORT_DROPDOWN_OPTIONS = REPORT_SORTS.map(s => (
  {
    label: s.label,
    value: s.key
  }
));

export const ROLES = {
  ADMIN: "Admin",
  DOCTOR: "Doctor",
  PROOFREADER: "Proofreader",
  RECEPTIONIST: "Receptionist",
  VENDOR: "Vendor",
};

export const STAFF_ROLES = [
  ROLES.ADMIN,
  ROLES.DOCTOR,
  ROLES.PROOFREADER,
  ROLES.RECEPTIONIST,
];

export const TIMEZONE = "America/New_York";
